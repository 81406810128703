export const navbar_top_items=[

    {
        label: "Home",
        path: '#masthead',
        type: 'link',
    },
    {
        label: "Gallery",
        path: '#gallery',
        type: 'link',
    },
    {
        label: "About",
        path: '#about',
        type: 'link',
    },

]